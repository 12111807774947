
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { AppConfigClient } from '@/services/Services';
import * as VM from '@/viewModel';

@Options({})
export default class FilterModal extends Vue {

    @Prop({
        default: () => []
    }) selectedTypeList: string[];
    localSelectedTypeList: string[] = [];

    @Prop({
        default: () => []
    }) selectedCategoryList: string[];
    localSelectedCategoryList: string[] = [];

    @Prop({
        type: [Function]
    }) callback: any;

    categories: VM.SelectableCategoryConfig[] = [];

    created() {
        this.localSelectedTypeList = this.selectedTypeList;
        this.localSelectedCategoryList = this.selectedCategoryList;

        AppConfigClient.getCategories()
        .then(x => {
            this.categories = x;
            this.categories.forEach(x => {
                x.selected = this.localSelectedCategoryList.some(c => c == x.name);
            })
        });
    }

    isTypeSelected(type: string) {
        var exist = this.localSelectedTypeList.some(c => c == type);
        if(exist)
            return "active";
    }
    toggleType(type: string) {
        var indexOf = this.localSelectedTypeList.indexOf(type);
        if(indexOf != -1) {
            this.localSelectedTypeList.splice(indexOf, 1);
        }
        else {
            this.localSelectedTypeList.push(type);
        }
    }

    isCategorySelected(cat: string) {
        var exist = this.localSelectedCategoryList.some(c => c == cat);
        if(exist)
            return "active";
    }
    toggleCategory(cat: string) {
        var indexOf = this.localSelectedCategoryList.indexOf(cat);
        if(indexOf != -1) {
            this.localSelectedCategoryList.splice(indexOf, 1);
        }
        else {
            this.localSelectedCategoryList.push(cat);
        }
    }

    filter() {
        this.callback(this.localSelectedTypeList, this.localSelectedCategoryList);
    }
    
}
