
import { Options, Vue } from 'vue-class-component';
import { BaseCommunityClient } from '@/services/Services';
import FilterModal from '../modal/filterModal.vue';
import CreateCommunity from '@/views/communities/modals/createCommunity.vue';
import * as OM from '@/model';

@Options({})
export default class Home extends Vue {

    loading: boolean = false;
    results: OM.DiscoverCardVM[] = [];

    interestDiscover = new OM.DiscoverFilterVM();
    notInterestDiscover = new OM.DiscoverFilterVM();

    finishedCommunities = false;

    created() {
        this.setDefaultFilter();
        this.init();
    }

    setDefaultFilter(){
        this.interestDiscover.skip = 0;
        this.interestDiscover.take = 10;
        this.interestDiscover.interestedCommunity = true;
        this.notInterestDiscover.skip = 0;
        this.notInterestDiscover.take = 10;
    }

    mounted(){
        (<any>this.$refs.scroll_section).addEventListener("scroll", detail => {
            // (<any>this.$refs.scroll_section).scrollHeight; scroll total height
            // (<any>this.$refs.scroll_section).scrollTop; how much it scrolled
            // (<any>this.$refs.scroll_section).offsetHeight; div dimensions

            var scrollHeight = (<any>this.$refs.scroll_section).scrollHeight
            var scrollAndDimension = (<any>this.$refs.scroll_section).offsetHeight + (<any>this.$refs.scroll_section).scrollTop;
            var percentage = scrollAndDimension / scrollHeight * 100;

            if(percentage >= 80)
                this.init();
        });
    }

    init() {      
        if(this.loading)
            return;

        if(this.finishedCommunities)
            return;
            
        this.loading = true;

        Promise.all([
            BaseCommunityClient.getDiscoverCommunity(this.interestDiscover, false),  
            BaseCommunityClient.getDiscoverCommunity(this.notInterestDiscover, false),  
        ])
        .then( x => {
            var tempCommunity: OM.DiscoverCardVM[] = [];
            var interested = x[0];
            var notInterested = x[1];

            if(interested.length == 0 && notInterested.length == 0){
                this.finishedCommunities = true;
                return;
            }
            
            tempCommunity.push(...interested);
            if(tempCommunity.length == 10){
                this.results.push(...tempCommunity);
                this.interestDiscover.skip += 10;
                return;
            }
            
            this.interestDiscover.skip += tempCommunity.length;
            var remainItems = 10 - tempCommunity.length;

            if(notInterested.length >= remainItems){
                tempCommunity.push(...notInterested.splice(0, remainItems));
                this.results.push(...tempCommunity);
                this.notInterestDiscover.skip += remainItems;
                return;
            }

            tempCommunity.push(...notInterested);
            this.results.push(...tempCommunity);
            this.notInterestDiscover.skip += notInterested.length;
        }).finally( () => {
            this.loading = false;
        })
    }

    get filtersAppliedCount() {
        return this.interestDiscover.categoryList.length + this.interestDiscover.typeList.length;
    }

    openDetail(type: string, slug: string) {
        
        if (type == 'community')
            this.$router.push('/communities/' + slug + '/Bacheca');
        else if (type == 'event')
            this.$router.push('/events/' + slug + '/Bacheca');
        else if (type == 'fiera')
            this.$router.push('/fiera/communities/' + slug);
    }

    openFilter() {
        var self = this;
        this.$opModal.show(FilterModal, {
            selectedTypeList: this.interestDiscover.typeList,
            selectedCategoryList: this.interestDiscover.categoryList,
            callback(typeFilters, catFilters) {
                self.setDefaultFilter();
                self.interestDiscover.typeList = typeFilters;
                self.interestDiscover.categoryList = catFilters;
                self.notInterestDiscover.typeList = typeFilters;
                self.notInterestDiscover.categoryList = catFilters;
                self.finishedCommunities = false;
                self.results = [];
                self.init();
                this.$opModal.closeLast();
            }
        })
    }

    openCreateCommunity(){
        this.$opModal.show(CreateCommunity, {
            
        });
    }

}
